import React, { Component } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Button from '../components/button'
import AnimatedBackgound from '../components/animatedBackground'
import codeReview from '../assets/illustrations/code-review.svg'
import phone from '../assets/illustrations/phone-white.svg'

import {
  headerHeight,
  headerHeight700,
  headerHeight500,
  footerHeight,
} from '../data/config'

const baseSectionCss = `
  align-items: center;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 1;
  width: 100%;
  min-height: 110vh;
  @media (max-width: 700px) {
    min-height: calc(100vh - ${headerHeight700}px);
  }
  @media (max-width: 500px) {
    min-height: calc(100vh -  ${headerHeight500}px);
  }
  :first-of-type {
    @media (min-width: 700px) {
      min-height: calc(100vh - ${headerHeight}px);
    }
  }
  :last-child {
    min-height: calc(100vh - ${footerHeight}px);
    @media (max-width: 700px) {
      min-height: calc(100vh - ${headerHeight700 + footerHeight}px);
    }
    @media (max-width: 500px) {
      min-height: calc(100vh -  ${headerHeight500 + footerHeight}px);
    }
  }
`

const productHeaders = [
  'Software',
  'Websites',
  'APIs',
  'E-commerce',
  'Experiences',
]

const productSections = [
  'software',
  'website',
  'API',
  'E-commerce',
  'experience',
]

class IndexPage extends Component {
  constructor() {
    super()
    this.state = {
      productHeaderCounter: 0,
      productSectionCounter: 0,
      productHeader: '',
      productSection: productSections[0],
    }
  }

  typeWord = word => {
    const extraDelay =
      productHeaders.indexOf(word) === productHeaders.length - 1 ? 1800 : 0
    for (let i = 0; i < word.length; i++) {
      let lastLetter = i === word.length - 1
      this.typeWordTimeout = setTimeout(() => {
        this.setState({
          productHeader: word.substr(0, i + 1),
        })
        if (lastLetter) {
          this.setState({
            productHeaderCounter: this.state.productHeaderCounter + 1,
          })
          this.deleteWordTimeout = setTimeout(() => {
            this.deleteWord(word)
          }, 1200 + extraDelay)
        }
      }, i * (90 - i))
    }
  }

  deleteWord = word => {
    for (let i = word.length - 1; i > -1; i--) {
      let firstLetter = i === 0
      let delayIndex = Math.abs(i - word.length + 1)
      this.deleteWordTimeout = setTimeout(() => {
        this.setState({
          productHeader: word.substr(0, i),
        })
        if (firstLetter) {
          this.typeWordTimeout = setTimeout(() => {
            this.typeWord(
              productHeaders[
                this.state.productHeaderCounter % productHeaders.length
              ]
            )
          }, 800)
        }
      }, delayIndex * (90 - delayIndex))
    }
  }

  componentDidMount() {
    const productCount = productHeaders.length
    this.typeWordTimeout = setTimeout(
      () => this.typeWord(productHeaders[0]),
      1000
    )
    this.productOfferingInterval = setInterval(() => {
      const newSection =
        productSections[this.state.productSectionCounter % productCount]
      this.setState({
        productSectionCounter: this.state.productSectionCounter + 1,
        productSection: newSection,
      })
    }, 2500)
  }

  componentWillUnmount() {
    clearInterval(this.productOfferingInterval)
    clearTimeout(this.deleteWordTimeout)
    clearTimeout(this.typeWordTimeout)
  }

  render() {
    return (
      <Layout fluid={true}>
        <section css={baseSectionCss}>
          <h1
            css={`
              color: #565656;
              position: relative;
              text-align: center;
              @media (max-width: 900px) {
                left: -115;
              }
              @media (max-width: 600px) {
                position: unset;
                padding-left: 5%;
                text-align: left;
                width: 95%;
              }
              @media (max-width: 500px) {
                margin-bottom: 10rem;
              }
            `}
          >
            We Build Quality
            <span
              css={`
                color: #2d476f;
                display: block;
                @media (max-width: 900px) {
                  text-align: left;
                }
              `}
            >
              {this.state.productHeader}
              <span
                css={`
                  font-size: 1.9rem;
                  position: relative;
                  top: -2px;
                  left: 2px;
                  animation: 1s blink step-end infinite;
                `}
              >
                |
              </span>
            </span>
          </h1>
        </section>
        <AnimatedBackgound key={1} />
        <section
          css={`
            ${baseSectionCss}
            color: #fefefe;
            background-color: #333;
            @media (max-width: 900px) {
              min-height: 90vh;
            }
          `}
        >
          <div
            css={`
              display: grid;
              align-items: center;
              column-gap: 1.0875rem;
              width: 805px;
              @media (max-width: 900px) {
                width: 70%;
              }
              @media (max-width: 600px) {
                width: 80%;
              }
              @media (max-width: 450px) {
                width: 90%;
              }
            `}
          >
            <h3
              css={`
                color: #8c85ff;
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 1;
                grid-row-end: 1;
                @media (max-width: 800px) {
                  grid-column-end: 3;
                }
              `}
            >
              Make your software the best it can be
            </h3>
            <p
              css={`
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 2;
                grid-row-end: 2;
              `}
            >
              Let us take on your project and create top-quality code. Free your
              people to do what matters most to your company.
            </p>
            <p
              css={`
                grid-column-start: 1;
                grid-column-end: 1;
                grid-row-start: 3;
                grid-row-end: 3;
                @media (max-width: 800px) {
                  grid-column-end: 3;
                }
                @media (max-width: 600px) {
                  min-width: 300px;
                }
              `}
            >
              Magnatome will deliver you a visually stunning and fully
              customized product. We guarantee a seamless handoff by providing
              documentation, testing and a hands on demo.
            </p>
            <Link
              to="/contact"
              css={`
                width: fit-content;
              `}
            >
              <Button
                secondary
                css={`
                  min-width: 155px;
                `}
              >
                Learn More
              </Button>
            </Link>
            <div
              css={`
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 4;
                justifyself: center;
                min-width: 120px;
                @media (max-width: 900px) {
                  width: 300px;
                }
                @media (max-width: 800px) {
                  alignself: start;
                  grid-row-start: 2;
                  width: 170px;
                }
                @media (max-width: 600px) {
                  width: 100%;
                  grid-row-end: 2;
                }
                @media (max-width: 450px) {
                  justifyself: end;
                  width: 150px;
                }
              `}
            >
              <img src={codeReview} alt="" />
            </div>
          </div>
        </section>
        <section css={baseSectionCss}>
          <div
            css={`
              display: grid;
              align-items: center;
              grid-template-columns: auto 225px 1.0875rem 48% auto;
              @media (max-width: 900px) {
                grid-template-columns: auto 175px auto 48% auto;
              }
              @media (max-width: 700px) {
                grid-template-columns: auto 135px 1.0875rem 48% auto;
              }
              @media (max-width: 500px) {
                grid-template-columns: 1.0875rem auto 1.0875rem 120px 1.0875rem;
              }
            `}
          >
            <div
              css={`
                grid-column-start: 2;
                grid-column-end: 2;
                grid-row-start: 1;
                grid-row-end: 6;
                justifyself: center;
                @media (max-width: 700px) {
                  alignself: baseline;
                  grid-row-start: 3;
                  grid-row-end: 5;
                }
                @media (max-width: 500px) {
                  grid-column-start: 4;
                  grid-column-end: 4;
                  grid-row-end: 6;
                }
              `}
            >
              <img alt="" src={phone} />
            </div>
            <h3
              css={`
                color: #6c63ff;
                grid-column-start: 4;
                grid-column-end: 4;
                grid-row-start: 2;
                grid-row-end: 2;
                @media (max-width: 700px) {
                  grid-column-start: 2;
                  grid-column-end: 5;
                }
              `}
            >
              Take your
              <span
                css={`
                  color: #3f3d56;
                  padding-left: 5px;
                  @media (max-width: 330px) {
                    display: block;
                    padding-left: 0;
                  }
                `}
              >
                {this.state.productSection}
              </span>
              <br
                css={`
                  @media (max-width: 330px) {
                    display: 'none';
                  }
                `}
              />
              to the next level
            </h3>
            <p
              css={`
                grid-column-start: 4;
                grid-column-end: 4;
                grid-row-start: 3;
                grid-row-end: 3;
                @media (max-width: 500px) {
                  grid-column-start: 2;
                  grid-column-end: 2;
                }
              `}
            >
              Whether you want to build a new product or improve an existing
              one, we have the skills and expertise for the task.
            </p>
            <p
              css={`
                grid-column-start: 4;
                grid-column-end: 4;
                grid-row-start: 4;
                grid-row-end: 4;
                @media (max-width: 500px) {
                  grid-column-start: 2;
                  grid-column-end: 2;
                  grid-row-start: 5;
                  grid-row-end: 5;
                }
              `}
            >
              We have delivered results to transit organizations, E-commerce
              companies, real estate firms, and non-profits. You can be next.
            </p>
          </div>
        </section>
      </Layout>
    )
  }
}

export default IndexPage
