import React, { useEffect, useState } from 'react'
import '../styles/animate.css'
import { headerHeight, headerHeight700, headerHeight500 } from '../data/config'

const AnimatedBackground = React.memo(() => {
  const [shapes, setShapes] = useState([])

  useEffect(() => {
    const backgroundColors = [
      '#7e104d',
      '#ae0f61',
      '#d62979',
      '#fd7011',
      '#f1bd00',
      '#0085d5',
      '#33b8f2',
      '#014fa0',
      '#21b4ed',
      '#293277',
    ]

    const circleCSS = `
      position: absolute;
      display: block;
      list-style: none;
      width: 20px;
      height: 20px;
      background: rgba(255, 255, 255, 0.2);
      animation: animateIndexHero 25s linear infinite;
      bottom: -150px;
    `

    const getBackgroundColor = index => {
      return backgroundColors[index % 10]
    }

    const buildCssObject = i => {
      const left = i * 9.8 + 5
      const heightWidth = Math.round(Math.random() * 45) + 15
      const animationDelay = Math.round(Math.random() * -14) - 12
      const animationDuration = Math.round(Math.random() * 14) + 12
      return `
        ${circleCSS}
        background-color: ${getBackgroundColor(i)};
        left: ${left}%;
        width: ${heightWidth}px;
        height: ${heightWidth}px;
        animation-delay: ${animationDelay}s;
        animation-duration: ${animationDuration}s;
      `
    }

    const buildShape = i => <li key={i} css={buildCssObject(i)}></li>
    const newShapes = []
    for (let i = 0; i < 10; i++) {
      newShapes.push(buildShape(i))
    }
    setShapes(newShapes)
  }, [])

  return (
    <div
      css={`
        background: rgba(255, 255, 255, 0.2);
        position: absolute;
        top: ${headerHeight}px;
        width: 100%;
        height: calc(100vh - ${headerHeight}px);
        overflow: hidden;
        z-index: 0;
        @media (max-width: 700px) {
          height: calc(100vh - ${headerHeight700}px);
          top: 0;
        }
        @media (max-width: 500px) {
          height: calc(100vh - ${headerHeight500}px);
        }
      `}
    >
      <ul
        css={`
          margin: 0;
        `}
      >
        {shapes}
      </ul>
    </div>
  )
})

export default AnimatedBackground
