import React from 'react'
import PropTypes from 'prop-types'

const Button = props => {
  return (
    <button
      className={props.className}
      type={props.type}
      css={`
        cursor: pointer;
        color: #fff;
        background-color: ${props.secondary ? '#6c63ff' : '#2d476f'};
        border-radius: 0.25rem;
        line-height: 1.1;
        padding: 0.7rem 1.5rem;
        border: none;
        appearance: none;
        user-select: none;
        :focus {
          outline: none;
        }
        :disabled {
          background-color: gray;
        }
      `}
    >
      {props.children}
    </button>
  )
}

Button.propTypes = {
  style: PropTypes.object,
}

export default Button
